import { Node } from 'tiptap'

export default class CustomDoc extends Node {
  get name() {
    return 'doc'
  }

  get schema() {
    return {
      content: 'title'
    }
  }
}
