/* eslint-disable comma-dangle */
<template>
  <div class="editor">
    <editor-content 
      class="editor__content" 
      :editor="editor"
      v-if="!complexEditor"
    />
    <ComplexEditor
      v-bind:editor_data.sync="textData"
      :placeholder="placeholder"
      editor-type="balloon"
      v-if="complexEditor"
    ></ComplexEditor>
  </div>
</template>

<script>

import { Editor, EditorContent, Extension } from 'tiptap/dist/tiptap.esm'
import { Placeholder } from 'tiptap-extensions/dist/extensions.esm'
import Title from './Title'
import Doc from './Doc'
import HandleKey from './HandleKey'
import ComplexEditor from '@/views/apps/questionnaires/elements/ComplexEditor'

export default {
  components: {
    EditorContent,
    ComplexEditor
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'h1'
    },
    updateTitle: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    },
    editorType: {
      type: String,
      default: 'classic',
    },
    debounceUpdate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      titleUpdateFunc: null,
      complexEditor: false,
      textData: '',
      editor: new Editor({
        autoFocus: true,
        extensions: [
          new Doc(),
          new Title(this.tag),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: node => {
              if (node.type.name === 'title') {
                return this.placeholder
              }
              return ''
            }
          }),
          new HandleKey({
            enterPressed: () => this.enterPressed()
          })
        ]
      })
    }
  },
  methods: {
    enterPressed() {
      if (this.titleUpdateFunc) {
        this.titleUpdateFunc()
      }
      this.$emit('title-ENTER_PRESSED')
    },
    checkEditorType() {
      this.complexEditor = /<.+>/g.test(this.textData)
    }
  },
  created() {
    this.editor.on('update', ({ getHTML, getJSON }) => {
      const jsonText = getJSON()
      const jsonTextContent = jsonText.content[0].content

      this.titleUpdateFunc = () => {
        if (this.item && this.item.data && this.item.data.editor_type) {
          this.item.data.editor_type = 'simple'
        }
        this.$emit('title-TITLE_UPDATED', this.item, this.type, this.isEmpty(jsonTextContent) ? '               ' : this.html2text(getHTML()))
      }

      if (this.debounceUpdate) {
        // Debounce tiptap update
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.titleUpdateFunc) {
            this.titleUpdateFunc()
          }
        }, 1000)
      } else if (this.titleUpdateFunc) {
        this.titleUpdateFunc()
      }
    })

    this.$on('HTML_TITLE_UPDATED', item => {
      try {
        if (!this.isEmpty(this.item) && !this.isEmpty(this.editor) && this.item.id === item.id) {
          this.editor.setContent(this.html2text(item.data.title))
        }
      } catch (e) {
        // Hide error because all checks on destroy event passed
      }
    })

    let content = ''
    if (this.type === 'option') {
      content = this.item.label
    } if (this.type === 'gap') {
      content = this.item.answer
    } else if (this.item && this.item.data) {
      content = this.item.data.title
    }

    if (!this.isEmpty(content)) {
      this.editor.setContent(content)
      this.editor.setSelection(0, content.length + 1)
      this.textData = content
    }

    this.checkEditorType()
    this.editor.focus()

  },
  mounted() {},
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.editor *.is-empty:nth-child(1)::before,
.editor *.is-empty:nth-child(2)::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
</style>
