import { Extension } from 'tiptap'

export default class HandleKey extends Extension {
    options: any;
    keys() {
        return {
            Enter: (state, dispatch, view) => {
                this.options.enterPressed()
                return true
            }
        }
    }
}